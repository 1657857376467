html, body, input {
    font-family: 'SourceSans Regular', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat Bold', sans-serif;
}

button {
    font-family: 'SourceSans Regular', sans-serif;
    font-size: 18px;
}

html, body, #root {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}
